import React from 'react';
import * as styles from './owl-exper-brands.module.scss';

import amgen from './static/exper-brands/Amgen.png';
import ibm from './static/exper-brands/IBM.png';
import charlesSchwab from './static/exper-brands/CharlesSchwab.png';
import apartments from './static/exper-brands/Apartments.com_.png';
import officeDepot from './static/exper-brands/OfficeDepot.png';
import img3m from './static/exper-brands/3m.png';
import google from './static/exper-brands/google.png';
import microsoft from './static/exper-brands/microsoft.png';
import motorola from './static/exper-brands/moto.png';
import nokia from './static/exper-brands/nokia.png';
import amazon from './static/exper-brands/amazon9.png';
import philips from './static/exper-brands/philips.png';
import yellowPages from './static/exper-brands/yellow_pages.png';
import nfl from './static/exper-brands/nfl.png';
import honey from './static/exper-brands/honey.png';
import lockheedMartin from './static/exper-brands/LockheedMartin.png';
import rockwellCollins from './static/exper-brands/RockwellCollins.png';
import intel from './static/exper-brands/Intel.png';

export default () => {
	const firstRow = (
		<div>
			<img src={img3m} alt="3m" />
			<img src={amazon} alt="amazon" />
			<img src={microsoft} alt="microsoft" />
			<img src={intel} alt="intel" />
			<img src={honey} alt="honey" />
			<img src={google} alt="google" />
		</div>
	);
	const secondRow = (
		<div>
			<img src={philips} alt="philips" />
			<img src={yellowPages} alt="yellow pages" />
			<img src={apartments} alt="apartments" />
			<img src={charlesSchwab} alt="charles schwab" />
			<img src={motorola} alt="motorola" />
			<img src={nokia} alt="nokia" />
		</div>
	);
	const thirdRow = (
		<div>
			<img src={amgen} alt="amgen" />
			<img src={nfl} alt="nfl" />
			<img src={ibm} alt="ibm" />
			<img src={officeDepot} alt="office depot" />
			<img src={rockwellCollins} alt="rockwell collins" />
			<img src={lockheedMartin} alt="lockheed martin" />
		</div>
	);
	return (
		<div className={styles.brandLogoWrapper}>
			<div className={styles.brandLogoRow}>
				{firstRow}
				{firstRow}
			</div>
			<div className={styles.brandLogoRow}>
				{secondRow}
				{secondRow}
			</div>
			<div className={styles.brandLogoRow}>
				{thirdRow}
				{thirdRow}
			</div>
		</div>
	);
};
