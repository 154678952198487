// extracted by mini-css-extract-plugin
export var servicesPage = "experience-module--servicesPage--2eNes";
export var ovalLogos = "experience-module--ovalLogos--2fKYF";
export var logoBorder = "experience-module--logoBorder--3Pg11";
export var headerLogo = "experience-module--headerLogo--3GCPF";
export var inlineBlueLogo = "experience-module--inlineBlueLogo--3tPJr";
export var inlineUxgGroupLogo = "experience-module--inlineUxgGroupLogo--274lf";
export var industryImages = "experience-module--industryImages--2OBo3";
export var industryImageRow = "experience-module--industryImageRow--1Y_kl";
export var aviation = "experience-module--aviation--B7AS2";
export var energy = "experience-module--energy--3wBdM";
export var healthcare = "experience-module--healthcare--1rY5J";
export var transport = "experience-module--transport--1r6VA";
export var travel = "experience-module--travel--3DU-1";
export var sub = "experience-module--sub--3xtg6";
export var sub2 = "experience-module--sub2--3lqCx";
export var sub3 = "experience-module--sub3--2oIsx";
export var content = "experience-module--content--38TOR";
export var contactLogos = "experience-module--contactLogos--1CBwl";