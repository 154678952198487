/* eslint-disable camelcase */
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import OwlExperBrands from '../components/owl-exper-brands';
import ContactWidget from '../components/contact-widget';
import Layout, { PageColors, PageTextColors } from '../components/layout';
import Footer from '../components/footer';
import AnimHeadline from '../components/anim-headline';
import * as styles from './experience.module.scss';
import { HeaderBetweenLogos } from '../components/header-between-logos';
import { HeroSection } from '../components/hero-section';
import img_ar2 from '../components/static/exper-projects/AR-2.jpg';
import img_ent from '../components/static/exper-projects/enterprisesoftware-2.jpeg';
import img_health from '../components/static/exper-projects/healthcareproduct-2.jpg';
import img_iot from '../images/iot.png';
import img_kiosk from '../images/kiosk.jpeg';
import img_tablet from '../images/tablet.jpeg';
import uxgBlueSlug from '../images/uxg-slug.png';
import uxologyGroupLogo from '../images/uxology-group-logo.png';
import experienceContactLeft from '../images/experience-contact-logo-left.png';
import experienceContactRight from '../images/experience-contact-logo-right.png';

import agriculture from '../images/experience/agriculture.png';
import appliances from '../images/experience/appliances.png';
import automotive from '../images/experience/automotive.png';
import biotech from '../images/experience/biotech.png';
import construction from '../images/experience/construction.png';
import energyTopLeft from '../images/experience/energy_top_left.png';
import energy from '../images/experience/energy.png';
import firstResponders from '../images/experience/first-responders.png';
import hospitality from '../images/experience/hospitality.png';
import internetOfThings from '../images/experience/internet-of-things.png';
import manufacturing from '../images/experience/manufacturing.png';
import mediaAndEntertainment from '../images/experience/media-and-entertainment.png';
import mining from '../images/experience/mining.png';
import consumerGoods from '../images/experience/consumer-goods.png';
import restaurant from '../images/experience/restaurant.png';
import retail from '../images/experience/retail.png';
import software from '../images/experience/software.png';
import technology from '../images/experience/technology.png';
import telecommunication from '../images/experience/telecommunication.png';
import education from '../images/experience/education.png';
import finance from '../images/experience/finance.png';
import remoteResearch from '../images/experience/remote-research.png';
import labBasedResearch from '../images/experience/lab-based-research.png';
import fieldBasedResearch from '../images/experience/field-based-research.png';

import aviationTopLeft from '../images/experience/aviation_top_left.png';
import aviationTopRight from '../images/experience/aviation_top_right.png';
import aviationBottomLeft from '../images/experience/aviation_bottom_left.png';
import aviationBottomRight from '../images/experience/aviation_bottom_right.png';

import healthcareTop from '../images/experience/healthcare_top.png';
import healthcareLeft from '../images/experience/healthcare_left.png';
import healthcareRight from '../images/experience/healthcare_right.png';

import transportBottom from '../images/experience/transport_bottom.png';
import transportLeft from '../images/experience/transport_left.png';
import transportRight from '../images/experience/transport_right.png';

import travelBottom from '../images/experience/travel_bottom.png';
import travelTop from '../images/experience/travel_top.png';
import travelRight from '../images/experience/travel_right.png';

export default function ServicesPage(props) {
	const render = () => {
		return (
			<Layout page="experience" location={props.location}>
				<div className={styles.servicesPage}>
					<HeroSection
						withBorder
						words={['TOP-NOTCH', 'EXPERIENCE']}
						bgColor={PageColors.experience}
						textColor={PageTextColors.experience}
					>
						OUR CONSULTANTS HAVE FORTUNE 100 EXPERIENCE <br />
						THROUGH MULTIPLE INDUSTRIES WORLDWIDE.
					</HeroSection>
					<div className={styles.ovalLogos} />
					<section className={styles.sub}>
						<div className={styles.content}>
							<h1>OUR BRAND EXPERIENCE</h1>
							<p>
								<img
									className={styles.inlineBlueLogo}
									src={uxgBlueSlug}
									alt="UXG Blue Slug"
								/>
								Again, our consultants have done global UX work at a wide
								variety of companies from startups to some of the most
								recognized brands on earth. We emphasize this because it's
								important to remember that regardless of how sophisticated UX is
								at your organization, there's probably room for outside help.
								Conducting research for such a vast collection of industries,
								organizations, and teams, enables us to see how the wide world
								of UX is undertaken throughout a large and highly representative
								sample of the marketplace. We know how to cut through the
								corporate noise surrounding most UX challenges and get at the
								heart of what users are thinking and doing. We also have the
								hard skills and educational backgrounds to understand what makes
								users tick and a driving focus on the soft skills that make us
								top-tier consultants.
							</p>
							<div className={styles.logoBorder} />
							<OwlExperBrands />
							<div className={styles.logoBorder} />
						</div>
					</section>

					<img
						className={styles.headerLogo}
						src={uxologyGroupLogo}
						alt="UXology logo"
					/>
					<section className={styles.sub2}>
						<div className="content container">
							<h1>OUR GEOGRAPHIC REACH</h1>
							<h3>Remote UX Research That's Spanned 6 Continents</h3>
							<img className="map" src={remoteResearch} alt="Remote research" />
							<h3>
								Lab-Based testing Capabilities In Major Cities Across The World
							</h3>
							<img
								className="map"
								src={labBasedResearch}
								alt="Lab based research"
							/>
							<h3>
								Field-Based Research Capabilities In Multiple Global Markets
							</h3>
							<img
								className="map"
								src={fieldBasedResearch}
								alt="Field based research"
							/>
							<img
								className={styles.headerLogo}
								src={uxologyGroupLogo}
								alt="UXology logo"
							/>
							<h1 style={{ marginTop: '3rem', zIndex: 1 }}>
								OUR INDUSTRY REACH
							</h1>
							<p style={{ marginBottom: '2rem' }}>
								<img
									className={styles.inlineUxgGroupLogo}
									src={uxologyGroupLogo}
									alt="UXology logo"
								/>
								We know how to elicit valuable user insights within any industry
								because we've earned our stripes through a far-reaching variety
								of challenging research projects. Flexing our intellect by
								applying our craft to new and interesting projects is what keeps
								us stimulated in this business. Our reach extends into virtually
								every industry.
							</p>

							<div className={styles.industryImages}>
								<div className={styles.industryImageRow}>
									<div>
										<img src={agriculture} alt="agriculture" />
										<strong>Agriculture</strong>
									</div>
									<div>
										<img src={appliances} alt="appliances" />
										<strong>Appliances</strong>
									</div>
								</div>
								<div className={styles.industryImageRow}>
									<div>
										<img src={automotive} alt="automotive" />
										<strong>Automotive</strong>
									</div>
									<div className={styles.aviation}>
										<img src={aviationTopLeft} alt="aviation-top-left" />
										<img src={aviationTopRight} alt="aviation-top-right" />
										<img src={aviationBottomLeft} alt="aviation-bottom-left" />
										<img
											src={aviationBottomRight}
											alt="aviation-bottom-right"
										/>
										<strong>Aviation</strong>
									</div>
								</div>
								<div className={styles.industryImageRow}>
									<div>
										<img src={biotech} alt="biotech" />
										<strong>Biotech</strong>
									</div>
									<div>
										<img src={construction} alt="construction" />
										<strong>Construction</strong>
									</div>
								</div>
								<div className={styles.industryImageRow}>
									<div>
										<img src={consumerGoods} alt="consumerGoods" />
										<strong>Consumer Goods</strong>
									</div>
									<div>
										<img src={education} alt="education" />
										<strong>Education</strong>
									</div>
								</div>
								<div className={styles.industryImageRow}>
									<div className={styles.energy}>
										<img src={energyTopLeft} alt="energy-top-left" />
										<img src={energy} alt="energy" />
										<strong>Energy & Utilities</strong>
									</div>
									<div>
										<img src={finance} alt="finance" />
										<strong>Finance</strong>
									</div>
								</div>
								<div className={styles.industryImageRow}>
									<div>
										<img src={firstResponders} alt="firstResponders" />
										<strong>First Responders</strong>
									</div>
									<div className={styles.healthcare}>
										<img src={healthcareTop} alt="healthcare-top" />
										<img src={healthcareLeft} alt="healthcare-left" />
										<img src={healthcareRight} alt="healthcare-right" />
										<strong>Healthcare</strong>
									</div>
								</div>
								<div className={styles.industryImageRow}>
									<div>
										<img src={hospitality} alt="hospitality" />
										<strong>Hospitality</strong>
									</div>
									<div>
										<img src={internetOfThings} alt="internetOfThings" />
										<strong>Internet of Things</strong>
									</div>
								</div>
								<div className={styles.industryImageRow}>
									<div>
										<img src={manufacturing} alt="manufacturing" />
										<strong>Manufacturing</strong>
									</div>
									<div>
										<img
											src={mediaAndEntertainment}
											alt="mediaAndEntertainment"
										/>
										<strong>Media & Entertainment</strong>
									</div>
								</div>
								<div className={styles.industryImageRow}>
									<div>
										<img src={mining} alt="mining" />
										<strong>Mining</strong>
									</div>
									<div>
										<img src={restaurant} alt="restaurant" />
										<strong>Restaurant</strong>
									</div>
								</div>
								<div className={styles.industryImageRow}>
									<div>
										<img src={retail} alt="retail" />
										<strong>Retail</strong>
									</div>
									<div>
										<img src={software} alt="software" />
										<strong>Software</strong>
									</div>
								</div>
								<div className={styles.industryImageRow}>
									<div>
										<img src={technology} alt="technology" />
										<strong>Technology</strong>
									</div>
									<div>
										<img src={telecommunication} alt="telecommunication" />
										<strong>Telecommunications</strong>
									</div>
								</div>
								<div className={styles.industryImageRow}>
									<div className={styles.transport}>
										<img src={transportLeft} alt="transport-left" />
										<img src={transportRight} alt="transport-right" />
										<img src={transportBottom} alt="transport-bottom" />
										<strong>Transport & Logistics</strong>
									</div>
									<div className={styles.travel}>
										<img src={travelTop} alt="travel-top" />
										<img src={travelBottom} alt="travel-bottom" />
										<img src={travelRight} alt="travel-right" />
										<strong>Travel</strong>
									</div>
								</div>
							</div>
						</div>
					</section>
					<img
						className={styles.headerLogo}
						src={uxologyGroupLogo}
						alt="UXology logo"
					/>
					<section className={styles.sub3} style={{ marginBottom: '-1.75rem' }}>
						<div className="content container">
							<h1 style={{ marginTop: '1rem', textTransform: 'uppercase' }}>
								Past Projects
							</h1>

							<div className="exper-outer">
								<div className="exper-stage">
									<div className="exper-item active">
										<div className="team-item">
											<div className="team-item-img">
												<img src={img_kiosk} className="" alt="" />
											</div>
											<HeaderBetweenLogos
												withBlueSlug
												className={styles.headerBetweenLogos}
												subTitle="Kiosks"
											/>
											<div className="team-item-info">
												<div className="team-item-text">
													<p>
														<strong>Goal</strong>: Uncover UX issues in a new
														store kiosk so they could be fixed before launch.
														<br />
														<br />
														<strong>Method</strong>: 1 round of field-based UX
														testing at a big box retail store. 1 month project
														duration, from kickoff meeting through delivery of
														report.
														<br />
														<br />
														<strong>Outcome</strong>: Recommendations used to
														increase visibility of the kiosk, likelihood of
														successful usage, user efficiency &amp;
														satisfaction.
													</p>
												</div>
												<ul className="team-item-social clearlist"></ul>
											</div>
										</div>
									</div>
									<div className="exper-item active">
										<div className="team-item">
											<div className="team-item-img">
												<img src={img_iot} />
											</div>
											<HeaderBetweenLogos
												withBlueSlug
												className={styles.headerBetweenLogos}
												subTitle="IoT Product, Packaging, &amp; Help Documentation"
											/>
											<div className="team-item-info">
												<div className="team-item-text">
													<p>
														<strong>Goal</strong>: Iteratively inform
														development of a new IoT product from early
														prototypes through launch.
														<br />
														<br />
														<strong>Method</strong>: 3 rounds of lab-based UX
														testing, 1 expert review, 4 rounds of field-based UX
														testing. 3 month project duration.
														<br />
														<br />
														<strong>Outcome</strong>: Continuously steered
														product team toward a better UX, resulting in
														stellar reviews/ratings upon launch.
													</p>
												</div>
												<ul className="team-item-social clearlist"></ul>
											</div>
										</div>
									</div>
									<div className="exper-item">
										<div className="team-item">
											<div className="team-item-img">
												<img src={img_ar2} />
											</div>
											<HeaderBetweenLogos
												withBlueSlug
												className={styles.headerBetweenLogos}
												subTitle="Mobile Apps &amp; Help Documentation"
											/>
											<div className="team-item-info">
												<div className="team-item-text">
													<p>
														<strong>Goal</strong>: Identify how to shorten the
														learning curve for an app so complex that only a
														handful of heavily trained people could use it
														successfully. <br />
														<br />
														<strong>Method</strong>: 6 rounds of field-based UX
														testing interspersed with expert reviews. 1 year
														project duration.
														<br />
														<br />
														<strong>Outcome</strong>: Uncovered dozens of HW,
														SW, and help documentation UX issues that
														dramatically affected learning curves.
													</p>
												</div>
												<ul className="team-item-social clearlist"></ul>
											</div>
										</div>
									</div>
									<div className="exper-item">
										<div className="team-item">
											<div className="team-item-img">
												<img src={img_tablet} />
											</div>
											<HeaderBetweenLogos
												withBlueSlug
												className={styles.headerBetweenLogos}
												subTitle="Websites (mobile, tablet, desktop)"
											/>
											<div className="team-item-info">
												<div className="team-item-text">
													<p>
														<strong>Goal</strong>: Determine how to improve the
														navigation for a responsive eCommerce website's
														product catalog.
														<br />
														<br />
														<strong>Method</strong>: 2 rounds of recorded remote
														UX testing and 1 round of live remote UX testing. 2
														month project duration.
														<br />
														<br />
														<strong>Outcome</strong>: Uncovered dozens of major
														usability issues which informed a product catalog
														redesign that is now much easier to navigate and
														use.
													</p>
												</div>
												<ul className="team-item-social clearlist"></ul>
											</div>
										</div>
									</div>
									<div className="exper-item">
										<div className="team-item">
											<div className="team-item-img">
												<img src={img_ent} />
											</div>
											<HeaderBetweenLogos
												withBlueSlug
												className={styles.headerBetweenLogos}
												subTitle="Enterprise Software"
											/>
											<div className="team-item-info">
												<div className="team-item-text">
													<p>
														<strong>Goal</strong>: Find the pain points in a
														financial SaaS product to inform a redesign.
														<br />
														<br />
														<strong>Method</strong>: 2 rounds of ethnographic
														observation, 5 rounds of remote UX testing, 1 round
														of lab-based UX testing. 8 month project duration.
														<br />
														<br />
														<strong>Outcome</strong>: Clearly explained
														surprisingly low discovery and success rates for
														some of the software's most important &amp;
														expensive features, leading to numerous
														enhancements.
													</p>
												</div>
												<ul className="team-item-social clearlist"></ul>
											</div>
										</div>
									</div>
									<div className="exper-item">
										<div className="team-item">
											<div className="team-item-img">
												<img src={img_health} />
											</div>
											<HeaderBetweenLogos
												withBlueSlug
												className={styles.headerBetweenLogos}
												subTitle="Physical Products"
											/>
											<div className="team-item-info">
												<div className="team-item-text">
													<p>
														<strong>Goal</strong>: Identify UX issues in a
														healthcare product to help improve the redesign.
														<br />
														<br />
														<strong>Method</strong>: 1 round of lab-based UX
														testing. 1 month project duration, from kickoff
														meeting through delivery of report.
														<br />
														<br />
														<strong>Outcome</strong>: Recommendations to improve
														the user-friendliness of the product and help
														documentation.
													</p>
												</div>
												<ul className="team-item-social clearlist"></ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<div className={styles.contactLogos}>
						<img src={experienceContactLeft} alt="UXG logo" />
						<img src={experienceContactRight} alt="UXG logo" />
					</div>
					<ContactWidget showSocial={true} />
					<Footer page="experience" location={props.location} />
				</div>
			</Layout>
		);
	};

	return (
		<StaticQuery
			query={graphql`
				{
					epxer1: file(relativePath: { regex: "/exper1.png/" }) {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
					epxer2: file(relativePath: { regex: "/exper2.png/" }) {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
				}
			`}
			render={(data) => render(data)}
		/>
	);
}
